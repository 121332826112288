import React from 'react';

import BlogList from '../../components/BlogList';
import RouterTracker from '../../components/RouterTracker';

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <div>
        <RouterTracker />
        <BlogList />
      </div>
    );
  }
}
