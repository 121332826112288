import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import { navigate } from 'gatsby-link';
import PreviewCompatibleImage from './PreviewCompatibleImage';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Tags from '../components/Tags';
import ButtonOutline from './ButtonOutline';

import ThemeContext from '../context/ThemeContext';

import SvgArrowRight from '../icons/ArrowRight';

var tinycolor = require('tinycolor2');

const BlogListStyles = styled.div(
  (props) => `
  padding: ${props.theme.size.base * 4}px
    ${props.theme.size.base * 4}px
    0;
`
);

const BlogList = (props) => {
  const breakpoints = useBreakpoint();
  const { data } = props;
  const { edges: posts } = data.allMarkdownRemark;

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <BlogListStyles theme={theme} breakpoints={breakpoints}>
          <div className='content'>
            {posts &&
              posts.map(({ node: post }) => (
                <div key={post.id}>
                  {PostSummary(post, theme, breakpoints, true)}
                </div>
              ))}
          </div>
        </BlogListStyles>
      )}
    </ThemeContext.Consumer>
  );
};

BlogList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogListQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 256)
              id
              fields {
                slug
              }
              frontmatter {
                title
                tags
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredimage {
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      quality: 60
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogList data={data} count={count} />}
  />
);

const PostDetails = styled.article(
  (props) => `
  ${
    props.theme.dark
      ? `
      background: ${props.theme.color.background[15]};
      background: linear-gradient(
        20deg,
        ${props.theme.color.background[65]},
        ${props.theme.color.background[45]}
      );
    `
      : `
    background: ${props.theme.color.background[90]};
    background: linear-gradient(
      20deg,
      ${props.theme.color.background[95]},
      ${props.theme.color.background[85]}
    );
    `
  };
  border-radius: ${props.theme.size.base * 1}px;
  margin-bottom: ${props.theme.size.base * 4}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  .summary {
    padding: ${props.theme.size.base * 4}px;
  }
  .featured-thumbnail {
     border-radius:
      ${props.theme.size.base}px 0 
      0 ${props.theme.size.base}px;
    overflow: hidden;
  }
  .date {
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.1em;
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    margin-bottom:  ${props.theme.size.base * 1.5}px;
    color: ${
      props.theme.dark
        ? props.theme.color.secondary[60]
        : props.theme.color.secondary[40]
    };
    transition: color 1200ms ease;
  }
  .tags {
    margin-bottom:  ${props.theme.size.base}px;
  }
  .title {
    display: block;
    font-weight: 700;
    font-size: ${props.theme.size.fontSize[6]};
    line-height: ${props.theme.size.lineHeight[6]};
    text-decoration: none;
    color: ${
      props.theme.dark
        ? props.theme.color.primary[60]
        : props.theme.color.primary[50]
    };
    transition: color 1200ms ease;
    &:hover {
      color: ${
        props.theme.dark
          ? props.theme.color.secondary[70]
          : props.theme.color.secondary[60]
      };
    }
  }
  .excerpt {
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    color: ${props.theme.color.foreground[50]};
    transition: color 1200ms ease;
    margin-top:  ${props.theme.size.base * 2}px;
  }
  .continue-reading {
    margin-top:  ${props.theme.size.base * 2}px;
  }
  ${
    (props.breakpoints.lg || props.breakpoints.xl) &&
    `
    flex-direction: row;
    .featured-thumbnail {
      flex: 0 0 40%;
      * {
        object-fit: cover;
        height: 100%;
      }
    }
    .summary {
      flex: 0 0 calc(60% - ${props.theme.size.base * 16}); 
      padding: ${props.theme.size.base * 8}px;
    }
  `
  };
`
);
function PostSummary(post, theme, breakpoints, isFeatured) {
  return (
    <PostDetails theme={theme} breakpoints={breakpoints}>
      {post.frontmatter.featuredimage ? (
        <div className='featured-thumbnail'>
          <Link to={post.fields.slug}>
            <PreviewCompatibleImage
              imageInfo={{
                image: post.frontmatter.featuredimage,
                alt: `featured image thumbnail for post ${post.frontmatter.title}`,
              }}
            />
          </Link>
        </div>
      ) : null}
      <div className='summary'>
        <div className='post-meta'>
          <div className='date'>{post.frontmatter.date}</div>
          {post.frontmatter.tags && post.frontmatter.tags.length ? (
            <div className='tags'>
              <Tags tags={post.frontmatter.tags}></Tags>
            </div>
          ) : null}

          <Link className='title' to={post.fields.slug}>
            {post.frontmatter.title}
          </Link>
        </div>
        {isFeatured && <div className='excerpt'>{post.excerpt}</div>}
        <div className='continue-reading'>
          <ButtonOutline
            onClick={() => {
              navigate(post.fields.slug);
            }}
            size={-1}
            shape={4}
            color='tertiary'
          >
            Continue Reading{' '}
            <SvgArrowRight
              style={{ marginLeft: theme.size.base * 1 }}
            ></SvgArrowRight>
          </ButtonOutline>
        </div>
      </div>
    </PostDetails>
  );
}
