import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import { Link } from 'gatsby';

import ThemeContext from '../context/ThemeContext';

var tinycolor = require('tinycolor2');

const TagsStyles = styled.div(
  (props) => `
  .taglist {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    li {
      margin-right: ${props.theme.size.base}px;
      margin-bottom: ${props.theme.size.base}px;
      white-sapce: nowrap;
      a {
        background: ${props.theme.color.background[100]};
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        color: ${tinycolor
          .mostReadable(props.theme.color.background[100], [
            props.theme.color.primary[75],
            props.theme.color.primary[40],
          ])
          .toHexString()};
        text-decoration: none;
        padding: 0 ${props.theme.size.base * 2}px;
        height: ${props.theme.size.base * 4}px;
        font-size: ${props.theme.size.fontSize[2]};
        line-height: ${props.theme.size.lineHeight[2]};
        font-weight: 600;
        letter-spacing: 0.1em;
        cursor: pointer;
        transition: color 450ms ease, background 450ms ease;
        &:hover {
          background: ${props.theme.color.background[80]};
          color: ${tinycolor
            .mostReadable(props.theme.color.background[80], [
              props.theme.color.primary[85],
              props.theme.color.primary[25],
            ])
            .toHexString()};
        }
      }
    }
  }
`
);

const Tags = (props) => {
  useEffect(() => {});

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <TagsStyles theme={theme}>
          <ul className='taglist'>
            {props.tags.map((tag) => (
              <li key={tag + `tag`}>
                <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
              </li>
            ))}
          </ul>
        </TagsStyles>
      )}
    </ThemeContext.Consumer>
  );
};

Tags.propTypes = {
  tags: PropTypes.array,
};

export default Tags;
