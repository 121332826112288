import React, { useEffect } from 'react';
import PlayerContext from '../context/PlayerContext';

const Closer = (props) => {
  useEffect(() => {
    props.context.setShowPlaylist(false);
    props.context.setMiniPlayer(true);
    props.context.setFirstVisit(false);
  }, []);

  return null;
};

const RouterTracker = (props) => {
  return (
    <PlayerContext.Consumer>
      {(context) => <Closer context={context} />}
    </PlayerContext.Consumer>
  );
};

export default RouterTracker;
